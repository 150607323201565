import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Router from "../../router/Router";
import './_dashboard.scss';

const DashboardLayout: React.FC = () => {
  return (
    <div>
      <Header />
      <div className="container home-container">
        <Router />
      </div>
      <Footer />
    </div>
  )
}

export default DashboardLayout;
