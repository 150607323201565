import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import PaginationComponent from './PaginationComponent';
import './_table.scss';

interface T {
  limit: number;
  offset: number;
}

interface Props {
  columns: Array<object>;
  dataSource: Array<object>;
  currentPageHandler?: (data: T) => void;
  getCheckList?: any;
  count?: number;
  loading?: boolean;
  urlOffset?: number;
  showPagination?: boolean;
  urlLimit?: number;
  locale?: any;
}

const TableComponent: React.FC<Props> = ({
  columns,
  dataSource,
  currentPageHandler,
  getCheckList,
  count,
  loading,
  urlOffset,
  showPagination,
  urlLimit,
  locale
}) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelectNone: () => {
      setSelectedRowKeys([]);
    },
  };

  useEffect(() => {
    if (getCheckList) {
      getCheckList(selectedRowKeys);
    }
  }, [selectedRowKeys]);

  return (
    <div className="table-component">
      <Table
        className="table"
        columns={columns}
        dataSource={dataSource}
        rowKey={(record: any) => record.id}
        {...(getCheckList && { rowSelection: { ...rowSelection } })}
        pagination={false}
        {...(loading && { loading: loading })}
        {...(locale && { locale: locale })}
      />
      {showPagination && (
        <PaginationComponent
          total={count}
          onPageChange={currentPageHandler}
          urlOffset={urlOffset}
          urlLimit={urlLimit}
        />
      )}
    </div>
  );
};

export default TableComponent;
