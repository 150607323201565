import { notification } from 'antd';

notification.config({
  maxCount: 1,
});

export const successNotification = (title: string, description?: string) => {
  notification.success({
    message: title,
    description: description,
    placement: 'bottom',
  });
};

export const errorNotification = (title: string, description?: string) => {
  notification.error({
    message: title,
    description: description,
    placement: 'bottom',
  });
};
