import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import chainReducer from './chain';

const store = configureStore({
  reducer: {
    auth: authReducer,
    chain: chainReducer,
  },
});

export default store;
