import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import './_button.scss';

interface Props {
  onClick?: () => void;
  type: 'primary' | 'secondary' | 'tertiary';
  className?: string;
  children?: any;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
}

const Button: React.FC<Props> = ({ onClick, className, children, loading, disabled, type, block = false }) => {
  return (
    <button
      type="button"
      className={`${type} ${block ? 'full-width' : ''} ${className ? className : ''} ${loading || disabled ? 'disabled' : ''} `}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {children}
      {loading && <LoadingOutlined className="btn-loader" />}
    </button>
  );
};

export default Button;
