import { Col, Row } from "antd";
import { useState } from "react";
import InsurerProfile from "./InsurerProfile";

const ViewYourClaims: React.FC = () => {
    const stats = [
        {
            title: 'Total Claims',
            value: '2,123',
            extraValue: '₹15,49,79,231',
            className: 'value',
        },
        {
            title: 'Claims Settled',
            value: '1,953',
            extraValue: '₹13,52,62,827',
            className: 'value',
        },
        {
            title: 'Claims Rejected',
            value: '170',
            extraValue: '₹1,97,16,404',
            className: 'value',
        },
        {
            title: 'Claim Settlement Ratio',
            value: '91.99%',
            extraValue: 'avg. CSR 90.5%',
            className: 'color-primary',
        },
    ],
        [dropdown, setDropdown] = useState('' as string),
        handleChange = (value: string) => {
            setDropdown(value);
        },
        docEntityMapping = { '': 'docs', 'group': "organisation's PAN/GST", 'individual': "individual's AADHAR" } as any,
        nameEntityMapping = { 'group': 'Safetynet Innovations Private Limited', 'individual': "Sanil Basutkar" } as any,
        yearFilter = [
            {
                label: '2019-20',
            },
            {
                label: '2020-21',
            },
            {
                label: '2021-22',
            },
        ],
        typeFilter = [
            {
                label: 'Group Health Insurance',
            },
            {
                label: 'Group Personal Accident',
            },
            {
                label: 'Group Term Life Insurance',
            },
        ],
        [activeType, setActiveType] = useState<undefined | string>(),
        [activeYear, setActiveYear] = useState<undefined | string>(),
        handleFilterChange = (value: string, type: string) => {
            if (type === 'Year') {
                setActiveYear(value);
            } else if (type === 'Type') {
                setActiveType(value);
            }
        };

    return (
        <div className="add-claim-individual">
            <Row gutter={16} className='mt-48'>
                {stats.map((stat, index) => (
                    <Col span={6} key={index}>
                        <div className="subheading">{stat?.title}</div>
                        <div className="mt-4">
                            <span className={`secondary-heading ${stat?.className}`}>{stat?.value}</span>
                            <span className="primary-text">&nbsp;&nbsp;({stat?.extraValue})</span></div>
                    </Col>
                ))}
            </Row>
            <div className="mt-16">
                <InsurerProfile />
            </div>
        </div>
    )
}

export default ViewYourClaims;
