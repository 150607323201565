import { createSlice } from '@reduxjs/toolkit';
import api from '../api/api';
import { deleteLocalData, getLocalData, setLocalData } from '../utils/helper';

const initialState = {
  isLoggedIn: getLocalData('token') || false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      setLocalData('token', action.payload.token);
      state.isLoggedIn = true;
    },
    logout: (state) => {
      deleteLocalData('token');
      state.isLoggedIn = false;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
