import { CheckCircleFilled, CloudDownloadOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Row, Select, Skeleton } from "antd";
import Search from "antd/lib/input/Search";
import { Option } from 'antd/lib/mentions';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import settledClaims from '../../assets/icons/Approved Claims.svg';
import rejectedClaims from '../../assets/icons/Rejected Claims.svg';
import totalClaims from '../../assets/icons/Total Claims.svg';
import SummaryCard from "../../components/UI/SummaryCard/SummaryCard";
import TableComponent from '../../components/UI/Table/TableComponent';
import { successNotification } from "../../components/UI/Toast/Toast";
import { renderStatus } from './ClientProfile';

interface IProps {
  intent?: string;
}

const VerifyClaims: React.FC<IProps> = ({ intent }) => {
  const [dropdown, setDropdown] = useState('' as string),
    [activeYear, setActiveYear] = useState<undefined | string>(),
    [activeType, setActiveType] = useState<undefined | string>(),
    [products, setProducts] = useState({} as any),
    [data, setData] = useState({} as any),
    { client } = useParams(),
    [loading, setLoading] = useState(true),
    [innerLoading, setInnerLoading] = useState(false),
    [searchValue, setSearchValue] = useState(null as any),
    history = createBrowserHistory({}),
    download = () => {
      successNotification('Claim history downloaded');
      window.open('https://healthysure-staging.s3.ap-south-1.amazonaws.com/Safetynet+Innovations+Private+Limited+Claims+Summary.pdf', "_blank")
    },
    downloadIndividualClaim = () => {
      successNotification('Claim downloaded');
      window.open('https://healthysure-staging.s3.ap-south-1.amazonaws.com/Safetynet+Innovations+Private+Limited+FJ01FX22.pdf', "_blank");
    },
    fetchData = (identifier: string, product?: string) => {
      let url = `https://ClaimsRepository.apple462.repl.co/api/transaction?identifier=${identifier}`;
      if (product) {
        url += `&product=${product}`;
      };
      setInnerLoading(true);
      axios.get(url)
        .then((res: any) => {
          console.log(res.data);
          setProducts(res?.data?.products);
          setData(res?.data);
          setLoading(false);
          setInnerLoading(false);
        }).catch((err: any) => {
          setLoading(false);
          setInnerLoading(false);
        })
    },
    navigate = useNavigate(),
    onSearch = (value: string) => {
      setSearchValue(value)
      setLoading(true);
      fetchData(value);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
    },
    handleChange = (value: string) => {
      setDropdown(value);
    },
    handleFilterChange = (value: string) => {
      setInnerLoading(true);
      setActiveType(value);
      fetchData(searchValue, value);
    },
    identifierNameMapping = {
      "718251262132": "Rohit Reddy",
      "GEUPD4872Q": "Rohit Reddy",
      "27ABDCS8132R1ZO": "Bspoke Wealth Management LLP",
      "ABDCS8922R": "Bspoke Wealth Management LLP",
      "GEUPD4877Q": "Sanil Basutkar",
      "718251362444": "Sanil Basutkar",
      "ABDCS8032R": "Safetynet Innovations Private Limited",
      "27ABDCS8032R1ZO": "Safetynet Innovations Private Limited",
    } as any,
    columns = [
      {
        title: 'Claim ID',
        dataIndex: 'claim_id',
        key: 'claim_id'
      },
      {
        title: 'Claimed on',
        dataIndex: 'date_of_claim',
        key: 'date_of_claim'
      },
      {
        title: 'Product',
        dataIndex: 'product',
        key: 'product'
      },
      {
        title: 'Claimed amount',
        dataIndex: 'claimed_amount',
        key: 'claimed_amount'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: any) => {
          return renderStatus(value)
        }
      },
      {
        title: 'Settled amount',
        dataIndex: 'settled_amount',
        key: 'settled_amount'
      },
      {
        title: 'Settled on',
        dataIndex: 'date_of_settlement',
        key: 'date_of_settlement'
      },
      // {
      //   title: '',
      //   dataIndex: 'download',
      //   key: 'download',
      //   float: 'right',
      //   render: (value: any) => {
      //     return <CloudDownloadOutlined className='primary-cta' onClick={downloadIndividualClaim} />
      //   }
      // },
    ],
    selfColumns = [
      {
        title: 'Claim ID',
        dataIndex: 'claim_id',
        key: 'claim_id'
      },
      {
        title: 'Claimed on',
        dataIndex: 'date_of_claim',
        key: 'date_of_claim'
      },
      {
        title: 'Product',
        dataIndex: 'product',
        key: 'product'
      },
      {
        title: 'Claimed amount',
        dataIndex: 'claimed_amount',
        key: 'claimed_amount'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: any) => {
          return renderStatus(value)
        }
      },
      {
        title: 'Settled amount',
        dataIndex: 'settled_amount',
        key: 'settled_amount'
      },
      {
        title: 'Settled on',
        dataIndex: 'date_of_settlement',
        key: 'date_of_settlement'
      },
      {
        title: '',
        dataIndex: 'download',
        key: 'download',
        float: 'right',
        render: (value: any) => {
          if (intent === 'self') {
            return <CloudDownloadOutlined className='primary-cta' onClick={downloadIndividualClaim} />
          }
        }
      },
    ];

  useEffect(() => {
    if (client && intent === 'self') {
      fetchData(client);
      setSearchValue(client);
    }
  }, [client]);

  return (
    <div className="add-claim">
      {intent !== 'self' && <Row align="middle" className="mt-32" gutter={32}>
        {/* <Col span={6}>
                    <div className="primary-text">Category</div>
                    <Select placeholder='Select entity' className='mt-4 full-width' onChange={handleChange}>
                        <Select.Option value="group">Group</Select.Option>
                        <Select.Option value="individual">Individual</Select.Option>
                    </Select>
                </Col> */}
        <Col span={24}>
          <div className="primary-text">Search</div>
          <Search placeholder={`search with GST Number/PAN/Aadhar number`} className='mt-4 full-width' onSearch={onSearch} enterButton />
        </Col>
      </Row>}

      {intent === 'self' && <div className="section-heading mt-40">
        <span className='pointer' onClick={() => navigate(-1)}>
          <LeftOutlined />
        </span>
        &nbsp;&nbsp;{identifierNameMapping?.[searchValue]} &nbsp;
        {/* <CheckCircleFilled style={{ color: '#11A387' }} /> */}
        <span className="primary-cta" onClick={download}><CloudDownloadOutlined /></span>
      </div>
      }


      {
        searchValue &&
        <>
          <Row align="top">
            <Skeleton loading={loading} active={true} className='mt-32' />
            {!loading && <>
              <Col span={24} className="mt-48">
                {intent !== 'self' && <div className="">Search Results</div>}
                {intent !== 'self' && <div className="title">{identifierNameMapping?.[searchValue]} &nbsp;<CheckCircleFilled style={{ color: '#11A387' }} />
                </div>}
                <Row align="middle" className="mt-24">
                  {data?.transactions?.[0]?.gst_no && <Col span={6}>
                    <div className="secondary-text">GST No.</div>
                    <div className="subheading">{data?.transactions?.[0]?.gst_no}</div>
                  </Col>}
                  <Col span={6}>
                    <div className="secondary-text">PAN No.</div>
                    <div className="subheading">{data?.transactions?.[0]?.pan_no}</div>
                  </Col>
                  {data?.transactions?.[0]?.aadhar_no && <Col span={6}>
                    <div className="secondary-text">Aadhar No.</div>
                    <div className="subheading">{data?.transactions?.[0]?.aadhar_no}</div>
                  </Col>}
                </Row>

              </Col>
              <Col span={24} className='mt-24'>
                <Row align='top' gutter={48}>
                  <Col span={8}>
                    <div className="title">Product</div>
                    <Select className="content select mt-16" defaultValue="" onChange={(value: any) => handleFilterChange(value)} bordered={false}>
                      <Option value="">All Products</Option>
                      {products && products?.map((product: any) => (
                        <Option value={product} key={product}>
                          {product}
                        </Option>
                      ))}
                    </Select>

                    {/* <Filter type='Year' filters={yearFilter} handleChange={(value: any) => handleFilterChange("Year", value)} active={activeYear} /> */}
                    {/* <Filter type='Type' filters={typeFilter} handleChange={handleFilterChange} active={activeType} /> */}
                  </Col>
                </Row>
                {/* </Col>
              <Col span={15} className="mt-24"> */}
                {/* <div className="title">
                  Claims Summary&nbsp;&nbsp;
                  <span className="primary-cta" onClick={download}><CloudDownloadOutlined /></span>
                </div> */}
                <Row align='top' gutter={[24, 24]} className="mt-32">
                  <Col span={6}>
                    <SummaryCard loading={innerLoading} title1='Claims Raised' value1={data?.total_claimed} title2='Value' value2={`₹${parseInt(data?.total_claimed_amount)?.toLocaleString('en-IN')}`} img={totalClaims} />
                  </Col>
                  <Col span={6}>
                    <SummaryCard loading={innerLoading} title1='Claims Settled' value1={data?.total_accepted} title2='Value' value2={`₹${parseInt(data?.total_accepted_amount)?.toLocaleString('en-IN')}`} img={settledClaims} />
                  </Col>
                  <Col span={6}>
                    <SummaryCard loading={innerLoading} title1='Claims Rejected' value1={data?.total_rejected} title2='Value' value2={`₹${parseInt(data?.total_rejected_amount)?.toLocaleString('en-IN')}`} img={rejectedClaims} />
                  </Col>
                  <Col span={6} style={{ height: "100%" }}>
                    <SummaryCard loading={innerLoading} title1='Claim Settlement Ratio' value1={data?.claim_settlement_ratio} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mt-48">
                <TableComponent
                  dataSource={data?.transactions}
                  columns={intent === 'self' ? selfColumns : columns}
                  loading={innerLoading}
                />
              </Col>
            </>
            }
          </Row>
        </>
      }
    </div>
  )
}

export default VerifyClaims;
