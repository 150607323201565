import axios from 'axios';

let LOCAL_ITEMS = ['accessToken'];

export const getParameterByName = (name: string, url: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getParametersBySubstring = (name: string, url: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let parts = window.location.search.slice(1).split('&');
  let result: string[] = [];
  parts.forEach((pair: any) => {
    pair = pair.split('=');
    if (pair[0].indexOf(name) > -1) {
      result.push(decodeURIComponent(pair[1]));
    }
  });
  return result.length ? result : null;
};

const STORAGE_TYPE = 'local';
export const setLocalData = (key: string, value: any) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  if (STORAGE_TYPE === 'local') {
    window.localStorage.setItem(key, value);
  } else if (STORAGE_TYPE === 'session') {
    window.sessionStorage.setItem(key, value);
  }
};

export const getLocalData = (key: any) => {
  if (STORAGE_TYPE === 'local') {
    let returnData: any = window.localStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  } else if (STORAGE_TYPE === 'session') {
    let returnData: any = window.sessionStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  }
};

export const deleteLocalData = (key: string) => {
  if (STORAGE_TYPE === 'local') {
    window.localStorage.removeItem(key);
  } else if (STORAGE_TYPE === 'session') {
    window.sessionStorage.removeItem(key);
  }
};

export const clearLocalData = () => {
  if (STORAGE_TYPE === 'local') {
    LOCAL_ITEMS.forEach((item) => {
      window.localStorage.removeItem(item);
    });
  } else if (STORAGE_TYPE === 'session') {
    LOCAL_ITEMS.forEach((item) => {
      window.sessionStorage.removeItem(item);
    });
  }
};

export const getAccessToken = () => {
  return getLocalData('accessToken') || null;
};

export const setAccessToken = (token: string) => {
  setLocalData('accessToken', token);
};

export const setRefreshToken = (token: string) => {
  setLocalData('refreshToken', token);
};

export const getRefreshToken = () => {
  return getLocalData('refreshToken') || null;
};

export const removeAxiosHeaders = () => {
  delete axios.defaults.headers.common['Authorization'];
};

export const setAxiosHeaders = (token = getAccessToken()) => {
  (function () {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  })();
};

export const toTitleCase = (text: string) => {
  if (!text) {
    return '';
  }
  text = text?.replaceAll('_', ' ');
  return text?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};