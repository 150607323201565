import { CloudDownloadOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Row, Select } from "antd";
import { Option } from 'antd/lib/mentions';
import { createBrowserHistory } from 'history';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import settledClaims from '../../assets/icons/Approved Claims.svg';
import rejectedClaims from '../../assets/icons/Rejected Claims.svg';
import totalClaims from '../../assets/icons/Total Claims.svg';
import Filter from "../../components/UI/Filter/Filter";
import SummaryCard from "../../components/UI/SummaryCard/SummaryCard";
import TableComponent from "../../components/UI/Table/TableComponent";
import Tag from "../../components/UI/Tags/Tag";
import { successNotification } from "../../components/UI/Toast/Toast";

export const renderStatus = (value: string) => {
    return <Tag type={value}>{value}</Tag>;
};

const ClientProfile: React.FC = () => {
    const { client } = useParams(),
        navigate = useNavigate(),
        [cardLoading, setCardLoading] = useState(false),
        [activeYear, setActiveYear] = useState<undefined | string>(),
        [activeType, setActiveType] = useState<undefined | string>(),
        history = createBrowserHistory({}),
        download = () => {
            // history.push('https://indiainsurtech.com/wp-content/uploads/2019/01/IIA-Logo-01-white.png');
            successNotification('Claim history downloaded');
        },
        downloadIndividualClaim = () => {
            successNotification('Claim downloaded');
        },
        yearFilter = [
            {
                label: '2019-20',
            },
            {
                label: '2020-21',
            },
            {
                label: '2021-22',
            },
        ],
        typeFilter = [
            {
                label: 'Group Health Insurance',
            },
            {
                label: 'Group Personal Accident',
            },
            {
                label: 'Group Term Life Insurance',
            },
        ],
        handleFilterChange = (value: string, type: string) => {
            setCardLoading(true);
            if (type === 'Year') {
                setActiveYear(value);
            } else if (type === 'Type') {
                setActiveType(value);
            }
            setTimeout(() => {
                setCardLoading(false);
            }, 1000);
        },
        columns = [
            {
                title: 'Claim ID',
                dataIndex: 'claim_id',
                key: 'claim_id'
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date'
            },
            {
                title: 'Product',
                dataIndex: 'product',
                key: 'product'
            },
            {
                title: 'Claimed amount',
                dataIndex: 'claimed_amount',
                key: 'claimed_amount'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (value: any) => {
                    return renderStatus(value)
                }
            },
            {
                title: 'Settled amount',
                dataIndex: 'settled_amount',
                key: 'settled_amount'
            },
            {
                title: '',
                dataIndex: 'download',
                key: 'download',
                float: 'right',
                render: (value: any) => {
                    return <CloudDownloadOutlined className='primary-cta' onClick={downloadIndividualClaim} />
                }
            },
        ],
        dataSource = [
            {
                claim_id: 1,
                date: '12/12/2020',
                product: "Group Health Insurance",
                claimed_amount: "1,35,000",
                status: 'SETTLED',
                age: 25,
                settled_amount: '1,20,000',
            },
            {
                claim_id: 2,
                date: '01/12/2020',
                product: "Group Personal Accident Insurance",
                claimed_amount: "3,74,000",
                status: 'REJECTED',
                age: 39,
                settled_amount: '-',
            },
            {
                claim_id: 3,
                date: '05/10/2020',
                product: "Group Term Life Insurance",
                claimed_amount: "2,50,000",
                status: 'SETTLED',
                age: 64,
                settled_amount: '60,000',
            },
            {
                claim_id: 4,
                date: '26/08/2020',
                product: "Group Health Insurance",
                claimed_amount: "35,000",
                status: 'SETTLED',
                age: 47,
                settled_amount: '35,000',
            }
        ]

    return (
        <div className="client-profile">
            <div className="section-heading mt-40">
                <span className='pointer' onClick={() => navigate(-1)}>
                    <LeftOutlined />
                </span>
                &nbsp;&nbsp;{client}
            </div>
            <Row align="middle" className="mt-24">
                <Col span={6}>
                    <div className="secondary-text">GST No.</div>
                    <div className="subheading">27ACV767638DSH</div>
                </Col>
                <Col span={6}>
                    <div className="secondary-text">PAN No.</div>
                    <div className="subheading">27ACV767638DSH</div>
                </Col>
                <Col span={6}>
                    <div className="secondary-text">Status</div>
                    <div className="subheading">Active</div>
                </Col>
            </Row>

            <Row className="mt-48">
                <Col span={8}>
                    <Row align='top' gutter={48}>
                        <Col span={18}>
                            <div className="title">Product</div>
                            <Select className="content select mt-16" defaultValue="" onChange={(value: any) => handleFilterChange("Type", value)} bordered={false} style={{ width: '100%' }}>
                                <Option value="">All Products</Option>
                                {typeFilter?.map((product: any) => (
                                    <Option value={product?.label} key={product?.label}>
                                        {product?.label}
                                    </Option>
                                ))}
                            </Select>

                            {/* <Filter type='Year' filters={yearFilter} handleChange={handleFilterChange} active={activeYear} /> */}
                            {/* <Filter type='Type' filters={typeFilter} handleChange={handleFilterChange} active={activeType} /> */}
                        </Col>
                    </Row>
                </Col>
                <Col span={15}>
                    <div className="title">Client Claims Summary&nbsp;&nbsp;
                        <span className="primary-cta" onClick={download}><CloudDownloadOutlined /></span>
                    </div>
                    <Row align='top' gutter={[24, 24]} className="mt-16">
                        <Col span={10}>
                            <SummaryCard loading={cardLoading} title1='Claims Raised' value1='2,123' title2='Value' value2='₹15,49,79,231' img={totalClaims} />
                        </Col>
                        <Col span={10}>
                            <SummaryCard loading={cardLoading} title1='Claims Settled' value1='1,953' title2='Value' value2='₹13,52,62,827' img={settledClaims} />
                        </Col>
                        <Col span={10}>
                            <SummaryCard loading={cardLoading} title1='Claims Rejected' value1='170' title2='Value' value2='₹1,97,16,404' img={rejectedClaims} />
                        </Col>
                        <Col span={10} style={{ height: "100%" }}>
                            <SummaryCard loading={cardLoading} title1='Claim Settlement Ratio' value1='90' />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-32">
                <Col span={22}>
                    <TableComponent loading={cardLoading} dataSource={dataSource} columns={columns} />
                </Col>
            </Row>
        </div>
    )
}

export default ClientProfile;
