import { FileSearchOutlined, InboxOutlined } from "@ant-design/icons";
import { Col, Divider, message, Row, Upload } from "antd";
import { NavLink } from 'react-router-dom';
import './_dashboard.scss';
const { Dragger } = Upload;

const props: any = {
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info: any) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.success(`${info.file.name} file uploaded successfully.`);
    }
  },
  onDrop(e: any) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};
const ViewChain: React.FC = () => {

  return (
    <div className="home">
      <Row justify='center' gutter={48} align="middle" className="mt-64 home-links">
        <Col span={10}>
          <NavLink to={'/chain'}>
            <div className="card">
              <Row justify='center'>
                <FileSearchOutlined className='primary-cta icon' />
              </Row>
              <div className="text-center title">View Data on Chain</div>
            </div>
          </NavLink>
        </Col>
        <Col>
          <Divider type='vertical' className='divider' />
        </Col>
        <Col span={10}>
          <Dragger {...props} className="mt-32" >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag csv/excel file to this area to upload</p>
            <p className="ant-upload-hint">
              For bulk upload.
            </p>
          </Dragger>
        </Col>
      </Row>


    </div>
  )
}

export default ViewChain;
