import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  chain : 'old'
};

const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    updateChain: (state) => {
      state.chain = 'new';
    },
  },
});

export const chainActions = chainSlice.actions;
export default chainSlice.reducer;
