import 'antd/dist/antd.css';
import { useMetaMask } from "metamask-react";
import { useDispatch, useSelector } from 'react-redux';
import './antd-custom.scss';
import './App.scss';
import './helper.scss';
import DashboardLayout from './pages/Dashboard/DashboardLayout';
import LoginLayout from './pages/Login/LoginLayout';


const App = () => {
  const isLoggedIn = useSelector((state: any) => state?.auth?.isLoggedIn),
  dispatch = useDispatch(),
  { status, connect, account, chainId, ethereum } = useMetaMask();

  if (isLoggedIn) {
    return (
      <DashboardLayout />
    )
  } else {
    return (
      <LoginLayout />
    );
  }
}

export default App;
