import { Col, Row, Spin, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TableComponent from '../../components/UI/Table/TableComponent';
import { errorNotification } from '../../components/UI/Toast/Toast';
import './_dashboard.scss';

const Chain: React.FC = () => {
    const activeChain = useSelector((state: any) => state.chain.chain),
        [chain, setChain] = useState<Array<any>>([]),
        [active, setActive] = useState(chain.length),
        [loading, setLoading] = useState(false),
        columns = [
            {
                title: 'Claim ID',
                dataIndex: 'claim_id',
                key: 'claim_id',
            },
            {
                title: 'Claimed Amount',
                dataIndex: 'claimed_amount',
                key: 'claimed_amount',
            },
            // {
            //     title: 'Claim Type',
            //     dataIndex: 'claim_type',
            //     key: 'claim_type',
            // },
            {
                title: 'Claimed on',
                dataIndex: 'date_of_claim',
                key: 'date_of_claim',
                // render: (record: any) => {
                //     return record ? moment(record).format('DD-MMM-YYYY, h:mm:ss a') : null;
                // },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (record: any) => {
                    return record ? <Tag color={record === "Accepted" ? "success" : "error"}>{record}</Tag> : null;
                },
            },
            {
                title: 'Settled Amount',
                dataIndex: 'settled_amount',
                key: 'settled_amount',
            },
            {
                title: 'Settled On',
                dataIndex: 'date_of_settlement',
                key: 'date_of_settlement',
                // render: (record: any) => {
                //     return record ? moment(record).format('DD-MMM-YYYY, h:mm:ss a') : null;
                // },
            },
            {
                title: 'Product',
                dataIndex: 'product',
                key: 'product',
            },
            {
                title: 'Identifier',
                dataIndex: 'identfier',
                key: 'identfier',
                render: (record: any, values: any) => {
                    return values?.pan_no || values?.gst_no || values?.aadhar_no
                }
            },
            {
                title: 'Documents',
                dataIndex: 'claim_document',
                key: 'claim_document',
            },
            {
                title: 'Additional Details',
                dataIndex: 'description',
                key: 'description',
            },
        ],
        getChain = () => {
            setLoading(true)
            fetch("https://ClaimsRepository.apple462.repl.co/api/chain").then((res: any) => {
                return res.json();
            }).then((data: any) => {
                setChain(data);
                setLoading(false)
            }).catch((err: any) => {
                errorNotification("Error reaching the chain");
            })
        }

    useEffect(() => {
        getChain();
    }, [])

    // useEffect(() => {
    //     if (activeChain === 'new') {
    //         setTimeout(() => {
    //             setChain(newChain)
    //         }, 1000)
    //     }
    //     else {
    //         setTimeout(() => {
    //             setChain(oldChain)
    //         }, 1000)
    //     }
    // }, [activeChain])

    useEffect(() => {
        setActive(chain.length)
    }, [chain])

    if (loading) {
        return <div className="loader-div">
            <div className="loader">
                <Spin />
            </div>
        </div>
    }


    return (
        <>
            <Row gutter={[0, 24]} className="mt-48 chain" align='middle'>
                {chain.map((item: any, index: number) => {
                    return (
                        <>
                            <Col className={`block text-center ${active === item.id ? 'active' : null}`} key={index} onClick={() => { setActive(item.id) }}>
                                <div className="subheading">{item.id}</div>
                                <div className="title mt-24">{item.transactions.length} Claims</div>
                                {/* <div className="primary-text mt-16">{item.insurer}</div> */}
                                <div className="secondary-text mt-4">{moment(item.timestamp).format('MMM Do YYYY')}</div>
                            </Col>
                            {index !== chain.length - 1 && <Col>
                                -------
                            </Col>}
                        </>
                    )
                })
                }
            </Row>

            {chain.length > 1 && <div className="mt-48 block-detail">
                <div className="title">Block Details</div>
                <Row gutter={[0, 16]} className="mt-24">
                    <Col span={6}>
                        <span className="subheading color-primary">Signature:</span>
                    </Col>
                    <Col span={18}>
                        <span className="subheading">{chain?.[active - 1]?.signature_key}</span>
                    </Col>
                    <Col span={6}>
                        <span className="subheading color-primary">Previous Hash:</span>
                    </Col>
                    <Col span={18}>
                        <span className="subheading">{chain?.[active - 1]?.previous_hash}</span>
                    </Col>
                    {/* <Col span={6}>
                        <span className="subheading color-primary">Insurer:</span>
                    </Col>
                    <Col span={18}>
                        <span className="subheading">{chain?.[active - 1]?.insurer}</span>
                    </Col> */}
                    <Col span={6}>
                        <span className="subheading color-primary">Claims:</span>
                    </Col>
                    {chain?.[active - 1]?.transactions.length > 0 ? <Col span={24}>
                        <span className="subheading">
                            <TableComponent columns={columns} dataSource={chain?.[active - 1]?.transactions} />
                        </span>
                    </Col> : <Col span={18}>
                        <span className="subheading">No claims</span>
                    </Col>}
                </Row>
            </div>}
        </>
    )
}

export default Chain;
