import { MetaMaskProvider } from "metamask-react";
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store/store';

const rootElement = document.getElementById('root');

render(
  <BrowserRouter>
    <MetaMaskProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MetaMaskProvider>
  </BrowserRouter>,

  rootElement
);