import { Col, Row } from 'antd';
import React from 'react';
import './_footer.scss';

const Footer: React.FC = () => {
  return (
    <div className='footer-wrapper'>
      <div className='footer'>
        <Row justify='space-between' align='middle' className="container">
          <Col>
            An initiative by Healthysure
          </Col>
          <Col>
            Blockchain Status: <span className='color-primary'>Online</span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
