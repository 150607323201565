import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Chain from '../pages/Dashboard/Chain';
import ClaimForm from '../pages/Dashboard/ClaimForm';
import Home from '../pages/Dashboard/Home';
import InsurerProfile from '../pages/Dashboard/InsurerProfile';
import UploadScreen from '../pages/Dashboard/UploadScreen';
import VerifyClaims from '../pages/Dashboard/VerifyClaim';
import ViewChain from '../pages/Dashboard/ViewChain';
import ViewYourClaims from '../pages/Dashboard/ViewYourClaims';
import LoginLayout from '../pages/Login/LoginLayout';

const Router: React.FC = () => {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  return (
    <Suspense
      fallback={
        <div className="loader-div">
          <div className="loader">
            <Spin />
          </div>
        </div>
      }
    >
      {isLoggedIn ? <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="home" element={<Home />} />
        <Route path="profile" element={<InsurerProfile />} />
        <Route path="chain-data" element={<ViewChain />} />
        <Route path="chain" element={<Chain />} />
        <Route path="verify-claims" element={<VerifyClaims />} />
        <Route path="view-your-claims" element={<ViewYourClaims />} />
        <Route path="client/:client" element={<VerifyClaims intent='self' />} />
        <Route path="upload" element={<UploadScreen />} />
        <Route path="add" element={<ClaimForm />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes> : <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="login" element={<LoginLayout />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>}
    </Suspense>
  )


};

export default Router;
