import { Col, Divider, Form, Image, Input, Row } from "antd";
import { useMetaMask } from "metamask-react";
import { useDispatch } from "react-redux";
import BC from "../../assets/icons/BC.png";
import Button from "../../components/UI/Button/Button";
import { errorNotification } from "../../components/UI/Toast/Toast";
import { authActions } from "../../store/auth";
import './_login.scss';


const LoginLayout: React.FC = () => {
  const dispatch = useDispatch(),
    [form] = Form.useForm(),
    { status, connect, account, chainId, ethereum } = useMetaMask(),
    handleLogin = () => {
      form.validateFields().then((values) => {
        if (values.username === 'NIA' && values.password === '123456') {
          dispatch(authActions.login({ token: "token" }));
        }
        else {
          errorNotification("Invalid credentials")
        }
      });
    };

  return (
    <div className="login container">
      <div className="login-container">
        <div className="section-heading text-center">
          Universal Claim Database
        </div>
        <Row align="middle" className="mt-48">
        <Col span={11}>
          <Image src={BC} preview={false} />
        </Col>
        <Col span={2}>
          <Divider type="vertical" style={{height: "300px"}} />
        </Col>
        <Col span={11} className="card">
          <Form
            name="login-form"
            className=""
            form={form}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          >
            <Form.Item
              name="username"
              label="Username"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="label"
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="label"
            >
              <Input type="password" />
            </Form.Item>
            <div className="mt-40">
              <Button block type="primary" onClick={handleLogin}>Login</Button>

              {/* {
              status === "notConnected" &&
              <Button block type="primary" onClick={connect}>Login</Button>
            }
            {
              status === "connecting" &&
              <Button block type="primary" disabled>Connecting..</Button>
            } */}
            </div>
            <div className="mt-16">
              {/* {status === "initializing" && <span>Synchronisation with MetaMask ongoing...</span>}
            {status === "unavailable" && <span>Metamask unavailable.</span>} */}
              {/* {status === "connecting" && <span>Logging you in....</span>} */}
              Forgot your password? <a href="#">Click here</a>
            </div>

          </Form>
        </Col>
        </Row>

      </div>
    </div >
  )
}

export default LoginLayout;
