import { CaretDownOutlined, InboxOutlined } from "@ant-design/icons";
import { Col, Dropdown, Image, message, Modal, Row, Space, Upload, UploadProps } from 'antd';
import { useMetaMask } from "metamask-react";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import newBlock from '../../assets/json/newBlock.json';
import { authActions } from '../../store/auth';
import Button from '../UI/Button/Button';
import { errorNotification, successNotification } from "../UI/Toast/Toast";
import './_header.scss';
const { Dragger } = Upload;

const props: UploadProps = {
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info: any) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.success(`${info.file.name} file uploaded successfully.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};

const Header: React.FC = () => {
  const dispatch = useDispatch(),
    [loading, setLoading] = useState(false),
    submit = () => {
      setLoading(true);
      updateChain();
      setLoading(false);
      // setTimeout(() => {
      //   dispatch(chainActions.updateChain());
      //   successNotification('Claim data synced');
      // }, 3000);
    },
    { status, connect, account, chainId, ethereum } = useMetaMask(),
    navigate = useNavigate(),
    [visible, setVisible] = useState(false),
    showModal = () => {
      setVisible(true);
    },
    handleOk = () => {
      setVisible(false);
      navigate('add');
    },
    handleCancel = () => {
      setVisible(false);
    },
    logoutHandler = () => {
      dispatch(authActions.logout());
    },
    menu = () => {
      return (
        <div className="header-menu">
          <div className="item" onClick={() => { dispatch(authActions.logout()) }}>
            Sign out
          </div>
        </div>
      )
    },
    updateChain = () => {
      fetch("https://ClaimsRepository.apple462.repl.co/api/transaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newBlock),
      }).then((res: any) => {
        return res.json();
      }).then((data: any) => {
        window.location.reload();
        successNotification("Chain updated");
      }).catch((err: any) => {
        errorNotification("Could not update the chain");
      })
    }

  return (
    <div className="header">
      <Row align='middle' justify='space-between' className='container'>
        <Col>
          <NavLink to={'/'}>
            <Image preview={false} src='https://icsi.newindia.co.in/images/nialogo-org.jpg' width={200} />
          </NavLink>
        </Col>
        <Col>
          <Row align='middle' gutter={24} justify="end">
            <Col>
              <Button type="secondary" className="primary-text cta" onClick={logoutHandler}>Logout</Button>
            </Col>
            {/* <Col>
              <Upload {...props}>
                <Button type='primary'>Upload Data&nbsp;</Button>
              </Upload>
            </Col> */}
            <Col>
              <Button type='primary' loading={loading} onClick={submit}>Sync Claims Data&nbsp;</Button>
              {/* <Button type='primary' onClick={() => { setVisible(true) }}>Upload Claims Data</Button> */}
            </Col>
            {/* <Col>
              <NavLink to={'chain'}>
                <Button type='primary' onClick={() => { }}>View Data on Chain&nbsp;</Button>
              </NavLink>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Modal title="Add claim(s)" open={visible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <div className="modal-content">
          <Row justify="center" className="mt-32"><Button type="primary" onClick={handleOk}>Single entry</Button></Row>
          <div className="mt-32" style={{ textAlign: 'center' }}>OR</div>
          <Dragger {...props} className="mt-32">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag csv/excel file to this area to upload</p>
            <p className="ant-upload-hint">
              For bulk upload.
            </p>
          </Dragger>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
