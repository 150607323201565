import { CheckCircleFilled, SecurityScanFilled, ThunderboltFilled } from '@ant-design/icons';
import { Col, Row, Select } from "antd";
import { createBrowserHistory } from 'history';
import { useState } from "react";
import { NavLink } from 'react-router-dom';
import { successNotification } from '../../components/UI/Toast/Toast';
import './_dashboard.scss';

const Home: React.FC = () => {
  const [active, setActive] = useState('summary' as string),
    [dropdown, setDropdown] = useState('' as string),
    [activeYear, setActiveYear] = useState<undefined | string>(),
    [activeType, setActiveType] = useState<undefined | string>(),
    [loading, setLoading] = useState(true),
    [searchValue, setSearchValue] = useState(null as any),
    history = createBrowserHistory({}),
    download = () => {
      // history.push('https://indiainsurtech.com/wp-content/uploads/2019/01/IIA-Logo-01-white.png');
      successNotification('Claim history downloaded');
    },
    onSearch = (value: string) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      setSearchValue(value)
    },
    { Option } = Select;

  return (
    <div className="home">
      <Row justify='center' gutter={48} align="middle" className="mt-64 home-links">
        <Col>
          <NavLink to='/verify-claims'>
            <div className="card">
              <Row justify='center'>
                <CheckCircleFilled className='primary-cta icon' />
              </Row>
              <div className="title">Verify Claims</div>
            </div>
          </NavLink>
        </Col>
        -------
        <Col>
          <NavLink to='/view-your-claims'>
            <div className="card">
              <Row justify='center'>
                <SecurityScanFilled className='primary-cta icon' />
              </Row>
              <div className="title">View Your Claims</div>

            </div>
          </NavLink>
        </Col>
        -------
        <Col>
          <NavLink to='/chain-data'>
            <div className="card">
              <Row justify='center'>
                <ThunderboltFilled className='primary-cta icon' />
              </Row>
              <div className="title">Chain Data</div>
            </div>
          </NavLink>
        </Col>
      </Row>


    </div>
  )
}

export default Home;
