import { Col, Image, Row, Skeleton } from 'antd';
import './_summary-card.scss';

interface Props {
    title1: string;
    value1: string;
    title2?: string;
    value2?: string;
    img?: string;
    loading?: boolean;
}

const SummaryCard: React.FC<Props> = ({ title1, title2, value1, value2, img, loading }) => {
    if (title2 && value2) {
        return (
            <div className="summary-card">
                <Row align='middle' gutter={24}>
                    <Col><Image src={img} preview={false} /></Col>
                    <Col>
                        <div className="primary-text">
                            {title1}
                        </div>
                        <div className="title">
                            {loading && <Skeleton.Button active shape='round' />}
                            {!loading && value1}
                        </div>
                        <div className="primary-text mt-16">
                            {title2}
                        </div>
                        <div className="title">
                            {loading && <Skeleton.Button active shape='round' />}
                            {!loading && value2}
                        </div>
                    </Col>
                </Row>

            </div>
        )
    } else {
        return (
            <div className="summary-card">
                <div className="primary-text">
                    {title1}
                </div>
                <div className={`primary-heading ${parseInt(value1) >= 90 ? 'green' : 'red'}`}>
                    {loading && <Skeleton.Button active shape='round' />}
                    {!loading &&
                        <>
                            {value1}%
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default SummaryCard;
