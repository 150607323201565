import React from 'react';
import { toTitleCase } from '../../../utils/helper';
import './_tag.scss';

interface Props {
  children: string;
  type: string;
}

const SUCCESS = ['success', 'accepted', 'active', 'settled'];

const FAIL = ['fail', 'rejected', 'cancelled', 'expired', 'inactive', 'warning'];

const PENDING = [
  'pending',
  'processing',
  'waiting',
  'unpaid',
  'unconfirmed',
  'unverified',
  'unapproved',
  'progress',
  'in-progress',
  'in progress',
  'in process',
  'in-process',
];

const INVITED = ['invited', 'inviting', 'invites'];

const Tag: React.FC<Props> = ({ children, type }) => {
  let className = '';
  let tagName = '';
  let renderType = toTitleCase(type)?.toLowerCase();
  if (SUCCESS.includes(renderType)) {
    className = 'success';
  } else {
    if (FAIL.includes(renderType)) {
      className = 'fail';
    } else {
      if (PENDING.includes(renderType)) {
        className = 'pending';
        tagName = 'In Process';
      } else {
        if (INVITED.includes(renderType)) {
          className = 'invited';
          tagName = 'INVITED';
        }
      }
    }
  }

  return <div className={`tag ${className}`}>{tagName !== '' ? tagName : toTitleCase(children)}</div>;
};

export default Tag;
