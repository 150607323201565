import { Button, Col, Form, Input, InputNumber, Radio, Row, Select } from "antd";
import { useState } from "react";
import { successNotification } from "../../components/UI/Toast/Toast";


const ClaimForm: React.FC = () => {
  const [form] = Form.useForm(),
    { Option } = Select,
    type = ['Group', 'Individual'],
    fieldMapping = {
      'Group': ['Group Health Insurace', 'Group Personal Accident', 'Group Term Life Insurance', 'Marine Insurance'],
      'Individual': ['Health Insurance', 'Term Life Insurance', 'Accident Insurance', 'Cyber Insurance', 'Travel Insurance']
    } as any,
    choices = {
      claim_status: ['Approved', 'Rejected'],
    },
    [product, setProduct] = useState('Group'),
    [loading, setLoading] = useState(false),
    submit = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        successNotification('Claim submitted successfully');
      }, 3000);
    },
    onChange = (e: any) => {
      setProduct(e.target.value);
    };


  return (
    <div className="mt-32">
      <Row justify="center">
        <Radio.Group onChange={onChange} value={product} defaultValue='Group'>
          {type?.map((field: any) => {
            return (
              <Radio value={field}>{field}</Radio>
            )
          }
          )}
        </Radio.Group>
      </Row>
      <Row gutter={24} justify='center'>
        <Form form={form} className='mt-32'>
          {product === "Group" && <Col>
            <Form.Item
              label="Aadhar Number"
              labelCol={{ span: 24 }}
              name="aadhar_number"
              className="label"
              validateTrigger="onBlur"
              validateFirst
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Enter Aadhar Number"
              />
            </Form.Item>
          </Col>}
          {product === "Individual" && <Col>
            <Form.Item
              label="PAN/GST Number"
              labelCol={{ span: 24 }}
              name="pan_gst_number"
              className="label"
              validateTrigger="onBlur"
              validateFirst
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Enter PAN/GST Number"
              />
            </Form.Item>
          </Col>}
          <Col>
            <Form.Item
              label="Product"
              labelCol={{ span: 24 }}
              name="product"
              validateTrigger="onBlur"
              rules={[{ required: true, message: 'Please select a product!' }]}
            >
              <Select
                showSearch
                placeholder="Select a Product"
                filterOption={(inputValue: any, option: any) =>
                  option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                }
              >
                {fieldMapping?.[product]?.map((data: any) => {
                  return (
                    <Option key={data} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Claim Amount"
              labelCol={{ span: 24 }}
              name="claimed_amount"
              validateTrigger="onBlur"
              rules={[{ required: true, message: 'Please enter a claim amount!' }]}
            >
              <InputNumber
                min={0}
                addonBefore="₹"
                style={{ width: '100%' }}
                formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Claim Status"
              labelCol={{ span: 24 }}
              name="status"
              validateTrigger="onBlur"
              rules={[{ required: true, message: 'Please select a claim status!' }]}
            >
              <Select
                showSearch
                placeholder="Select a Claim Status"
                filterOption={(inputValue: any, option: any) =>
                  option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                }
              >
                {choices?.claim_status?.map((data: any) => {
                  return (
                    <Option key={data} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          {/* {form.getFieldValue('status') !== 'Rejected' && */}
          <Col>
            <Form.Item
              label="Settled Amount"
              labelCol={{ span: 24 }}
              name="settled_amount"
              validateTrigger="onBlur"
              rules={[{ required: true, message: 'Please enter a claim amount!' }]}
            >
              <InputNumber
                min={0}
                addonBefore="₹"
                max={form.getFieldValue('claimed_amount')}
                style={{ width: '100%' }}
                formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
              />
            </Form.Item>
          </Col>
        </Form>
      </Row>
      {
        product &&
        <Row justify="center">
          <Col span={6}>
            <Button loading={loading} type="primary" block className="mt-32" onClick={submit}>Submit Claim</Button>
          </Col>
        </Row>
      }
    </div>
  )
}

export default ClaimForm;
