import { Col } from "antd";
import Search from "antd/lib/input/Search";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../components/UI/Table/TableComponent";

const InsurerProfile: React.FC = () => {
    const [params, setParams] = useState({} as any),
        navigate = useNavigate();
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value: any, record: any) => {
                return (
                    <div className="cta client-name" onClick={() => { navigate(`/client/${record?.identifier}`) }}>{value}</div>
                )
            }
        },
        {
            title: 'GST/PAN',
            dataIndex: 'identifier',
            key: 'identifier'
        },
        {
            title: 'Products',
            dataIndex: 'products',
            key: 'products',
        },
    ],
        dataSource = [
            {
                name: 'Safetynet Innovations Private Limited',
                identifier: 'ABDCS8032R',
                products: '3 products',
            },
            {
                name: 'Sanil Basutkar',
                identifier: 'GEUPD4877Q',
                products: '2 product',
            },
            // {
            //     name: 'Rohit Reddy',
            //     identifier: 'GEUPD4872Q',
            //     products: '3 products',
            // },
            {
                name: 'Bspoke Wealth Management LLP',
                identifier: 'ABDCS8922R',
                products: '1 product',
            }
        ],
        filter = [
            {
                id: 1,
                title: 'Group',
                value: 'Group',
            },
            {
                id: 2,
                title: 'Individual',
                value: 'Individual',
            },
        ],
        getParams = (filter: object) => {
            setParams((prev: any) => {
                return { ...prev, ...filter, offset: 0, limit: 10 };
            });
        };

    return (
        <div className="insurer-profile">
            {/* <TableCTA
                getParams={getParams}
                filters={filter}
                // customCTA={customCTA}
                // dataSource={reformatDownloadData()}
                showSearch={true}
            /> */}
            <Col span={24}>
                <div className="primary-text">Search</div>
                <Search placeholder={`search with GST Number/PAN/Aadhar number`} className='mt-4 full-width' enterButton />
            </Col>
            <div className="mt-32">
                <TableComponent
                    // currentPageHandler={currentPageHandler}
                    // count={count}
                    dataSource={dataSource}
                    columns={columns}
                // urlOffset={params.offset}
                // urlLimit={params.limit}
                // showPagination={true}
                // locale={locale}
                />
            </div>

        </div>
    )
}

export default InsurerProfile;
